@import "../scss/settings";

.print-only {
	display: block;
  background: $dark-blue !important;
  padding: 20px 0;
  img {
    padding: 0 !important;
  }
}

.hide {
	display: none;
}

body, html {
  // -webkit-print-color-adjust: exact;
}

body:before, html:before {
  display: none;
}

*:before, *:after {
  background: transparent !important;
}

header.header,
footer.footer {
  display: none;
}

.order-step-indicator {
  display: none;
}

.footer-spacer {
  display: none;
}
